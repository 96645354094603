import { useContext } from 'react';
import { Alert, List, Box, IconButton, Collapse } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import { ProspectsContext } from '../../contexts/ProspectsContext';

function ErrorStack() {
  const { prospectErrors, removeError } = useContext(ProspectsContext);

  const handleDelete = (index) => {
    removeError(index);
  };

  const errors = [...prospectErrors];

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, p: 3, zIndex: 1 }}>
      <List maxWidth="xl" spacing={1}>
        <TransitionGroup>
          {errors.map((err, index) => (
            <Collapse key={index}>
              <Alert
                action={
                  <IconButton color="inherit" size="small" onClick={() => handleDelete(index)}>
                    <CloseIcon />
                  </IconButton>
                }
                variant="outlined"
                color="error"
                severity="error"
                sx={{ mb: 1 }}
              >
                {err.message}
              </Alert>
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
    </Box>
  );
}

export default ErrorStack;
