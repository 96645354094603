import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/home" replace />
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" replace /> },
        { path: 'home', element: <Home /> },
        { path: 'net_new_leads', element: <NetNewLeads /> },
        { path: 'contract_renewal', element: <ContractRenewals /> },
        { path: 'new_locations_at_existing_clients', element: <NewLocationsAtExistingClients /> },
        { path: `client_details/:prospecting_type/:company/:contact`, element: <ContactDetails /> },
        { path: 'client_details/:prospecting_type/:company', element: <AccountDetails /> },
        { path: 'client_details/:prospecting_type/:company/location/:location', element: <LocationDetails /> },
        { path: 'settings/:activeTab', element: <Settings /> },
        { path: 'settings', element: <Settings /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard

const Home = Loadable(lazy(() => import('../pages/Home')));
const NetNewLeads = Loadable(lazy(() => import('../pages/NetNewLeads')));
const ContractRenewals = Loadable(lazy(() => import('../pages/ContractRenewals')));
const NewLocationsAtExistingClients = Loadable(lazy(() => import('../pages/NewLocationsAtExistingClients')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ContactDetails = Loadable(lazy(() => import('../pages/ContactDetails')));
const AccountDetails = Loadable(lazy(() => import('../pages/accountDetails')));
const LocationDetails = Loadable(lazy(() => import('../pages/LocationDetails')));
const Settings = Loadable(lazy(() => import('../pages/settings')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
