// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('dashboard'),
  netNewLead: getIcon('new_net_lead'),
  contractRenewals: getIcon('contract_renewals'),
  accountExpansion: getIcon('account_expansion'),
  activeProspects: getIcon('active_prospects')
};

const sidebarConfig =
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Home', path: PATH_DASHBOARD.general.home, icon: ICONS.dashboard },
      { title: 'Net-New Leads', path: PATH_DASHBOARD.general.netNewLeads, icon: ICONS.netNewLead },
      { title: 'Contract Renewals', path: PATH_DASHBOARD.general.contractRenevals, icon: ICONS.contractRenewals },
      {
        title: 'Location Expansion',
        path: PATH_DASHBOARD.general.newLocationsAtExistingClients,
        icon: ICONS.activeProspects
      }
    ]
  };
export default sidebarConfig;
