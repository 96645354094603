import { useContext } from 'react';
import { Box, Chip } from '@mui/material';
/* import OpenInNewIcon from '@mui/icons-material/OpenInNew'; */
import { SocketContext } from '../../../../contexts/SocketContext';

function ReferenceList(props) {
  const { setChatSidebarConfig } = useContext(SocketContext);

  function handleOpenDebugClick() {
    setChatSidebarConfig({ debug: props.debug_info });
  }
  /* 	const links = props?.sources?.split(')'); */

  return (
    <Box sx={{ minWidth: '100%', mt: 1 }}>
      {props.debug_info && (
        <Chip
          onClick={() => handleOpenDebugClick()}
          label="Debug Log"
          variant="outlined"
          sx={{ mr: 0.5 }}
          size="small"
        />
      )}
      {/* 	{links && (
				<>
					<Typography sx={{ opacity: 0.8, mr: 1 }} variant="caption">
						Sources:{' '}
					</Typography>
					{links.map(
						link =>
							link && (
								<Chip
									label={link.substring(
										link.indexOf('[') + 1,
										link.lastIndexOf(']')
									)}
									variant="outlined"
									sx={{ mr: 0.5 }}
									size="small"
									clickable
									href={link.slice(link.indexOf('(') + 1)}
									icon={<OpenInNewIcon color="primary" />}
									component="a"
									target="_blank"
								/>
							)
					)}
				</>
			)} */}
    </Box>
  );
}

export default ReferenceList;
