import { useState, useEffect, useRef } from 'react';
import {
  OutlinedInput,
  InputAdornment,
  List,
  Box,
  ListItem,
  ListItemText,
  Card,
  CircularProgress,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@mui/material/styles';
import { Circle } from '@mui/icons-material';
import { API } from 'aws-amplify';
import Label from './Label';
import { searchAccount } from '../api/prospects';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '50vw',
  [theme.breakpoints.down('lg')]: {
    width: '40vw'
  },
  [theme.breakpoints.down('md')]: {
    width: '30vw'
  },
  height: 43,
  fontSize: 14,
  background: theme.palette.grey[200],
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { boxShadow: `${theme.palette.text.secondary} 0px 2px 5px -4px !important` },
  '& fieldset': {
    borderWidth: `1px !important`,
    border: `${theme.palette.grey[200]} !important`
  }
}));

function NavSearch() {
  const lastSearchInstance = useRef();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    if (search) {
      setShowList(true);
      fetchAccountList();
    } else {
      setShowList(false);
      setAccountList([]);
    }
  }, [search]);

  const fetchAccountList = async () => {
    const instance = searchAccount(search);
    if (lastSearchInstance.current) {
      API.cancel(lastSearchInstance.current, 'Cancel');
    }
    lastSearchInstance.current = instance;
    setIsLoading(true);
    try {
      const response = await instance;
      setAccountList(response.data?.prospects);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      if (error?.message !== 'Cancel') setIsLoading(false);
    }
  };

  return (
    <Box sx={{ ml: 1, position: 'relative' }}>
      <SearchStyle
        startAdornment={
          <InputAdornment position="start">
            <Icon fontSize={20} icon={searchFill} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {isLoading && <CircularProgress size={15} thickness={4} color="inherit" />}
          </InputAdornment>
        }
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
        fullWidth
        onBlur={() => setShowList(false)}
      />
      {showList > 0 && (
        <Card
          sx={{
            position: 'absolute',
            left: 0,
            top: 48,
            width: '45vw',
            maxHeight: '80vh',
            zIndex: 1,
            overflow: 'auto'
          }}
        >
          <List disablePadding>
            {accountList.map((account) => (
              <ListItem
                onMouseDown={() => {
                  navigate(`/client_details/${account.prospect_type}/${account.prospect_id}`);
                  setSearch('');
                }}
                key={account.prospect_id}
                button
                divider
                secondaryAction={
                  <Label sx={{ textTransform: 'capitalize' }}>{account.prospect_type.replace(/_/g, ' ')}</Label>
                }
              >
                <Circle sx={{ color: account.active ? 'success.main' : 'lightgray', height: 11, width: 11, mr: 1.5 }} />
                <ListItemText primary={account.company_name} secondary="Click to see details" />
              </ListItem>
            ))}
          </List>
          {!isLoading && !accountList?.length && search && (
            <Box display="flex" justifyContent="center" sx={{ p: 3, textAlign: 'center' }}>
              <Icon style={{ marginRight: 10 }} icon="tdesign:search-error" width={24} height={24} />
              <Typography color="text.secondary">No results found for &quot;{search}&quot;</Typography>
            </Box>
          )}
        </Card>
      )}
    </Box>
  );
}

export default NavSearch;
