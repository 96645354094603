export const RIGHT_SIDEBAR_WIDTH = '390px';
export const CONVERSATIONAL_TYPE = 'conversational_knowledgebase';
export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 140;
export const ADMIN_ROLE = 'admin';
export const CIRCLE_COLOR = {
  AMBER: '#FFCA41',
  RED: '#FF0000',
  GREEN: '#6AD529'
};

// ------------------- Account Info Page Sections ---------------------------------

export const PAGE_SECTIONS = [
  { id: 'account', label: 'Account Info' },
  { id: 'location', label: 'Location Details' },
  { id: 'opportunity', label: 'Opportunity Details' },
  { id: 'contacts', label: 'Contacts' },
  { id: 'contracts', label: 'Contract Details' }
];

// ------------------- Account Info Field By Section ---------------------------------

export const FIELDS_BY_SECTION = {
  account: ['Account Owner', 'Parent Account Name', 'Headquarters', 'Industry', 'Total Employees', 'Customer Number'],
  location: ['Status', 'Street Address', 'City', 'State', 'Lit', 'Fiber Tier', 'Rag Score'],
  opportunity: ['Opportunity Name', 'Created Date', 'Sales Stage', 'Opportunity Owner', 'Opportunity Id'],
  contacts: ['Name', 'Title', 'Office Phone', 'Email'],
  contracts: ['Account Number', 'Renewal', 'Expiration Date', 'Term', 'Monthly Bill', 'Contract Id', 'RAG Score']
};
