import { useState } from 'react';
import { IconButton, Stack, Popover, Paper, MenuItem, Tooltip } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ClearAllIcon from '@mui/icons-material/ClearAll';

function ChatToolbar({ resetSession, resetAll, isMobile }) {
  const [anchor, setAnchor] = useState(null);

  return (
    <>
      <Tooltip title="Conversation Reset Action">
        <IconButton onClick={(e) => setAnchor(e.currentTarget)} size="small">
          <RestartAltIcon color="action" fontSize={isMobile ? 'small' : 'medium'} />
        </IconButton>
      </Tooltip>
      <Popover
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
        open={Boolean(anchor)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 90
        }}
      >
        <Paper variant="outlined">
          <Stack>
            <MenuItem
              divider
              onClick={() => {
                setAnchor(null);
                resetSession();
              }}
            >
              <RestartAltIcon fontSize="small" sx={{ mr: 1 }} />
              Reset Session
            </MenuItem>
            <MenuItem onClick={resetAll}>
              <ClearAllIcon fontSize="small" sx={{ mr: 1 }} />
              Clear History
            </MenuItem>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
}

export default ChatToolbar;
