export const formatDate = (datetime, showTime = false, locale = 'en-US') => {
  const date = new Date(datetime);

  const timeProps = { hour: 'numeric', minute: 'numeric' };
  const options = {
    year: 'numeric',
    day: 'numeric',
    month: 'numeric'
  };
  const dateTimeFormat = new Intl.DateTimeFormat(locale, showTime ? { ...options, ...timeProps } : options);
  return dateTimeFormat.format(date);
};

export const formatCurrency = (amount) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

export const getDaysFromDate = (eDate) => {
  const date = new Date(0);
  date.setUTCSeconds(eDate);
  const timeinmilisec = new Date().getTime() - date.getTime();
  return Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
};

export const formatDistance = (distance) => Number(distance).toLocaleString('en-US', { maximumFractionDigits: 2 });

export const removeTagsFromString = (str) => str.replace(/(<([^>]+)>)/gi, '');
