import { useState, memo, useEffect, useContext } from 'react';
import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  TextField,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  ThumbUp,
  ThumbDown,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
  InfoOutlined,
  ContentCopy
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { formatDate } from '../../../utils';
import ReferenceList from '../ChatWidgets/ReferenceList';
import { SocketContext } from '../../../contexts/SocketContext';

import TextSlider from '../../../components/TextSlider';
import Loader from '../Loader/Loader';
import styles from '../styles';

const POSITIVE_RATING = 'positive';
const NEGATIVE_RATING = 'negative';
const LOADING_MESSAGES = [
  '',
  'Checking the knowledge base',
  'Using your available tools',
  'Generating your results',
  'Thanks for your patience, this is a tough one'
];

const useStyles = makeStyles(styles);

function TextElement(props) {
  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{props.text}</ReactMarkdown>;
}

function ButtonElement(props) {
  function handleChipClick() {
    console.log('Button clicked');
  }

  return <Chip onClick={() => handleChipClick()} label={props.label} variant="outlined" clickable />;
}

function Element({ data }) {
  switch (data.element) {
    case 'text':
      return !data.text || data.text === '...' ? (
        <Box display="flex">
          <Loader />
          <TextSlider interval={7500} textArray={LOADING_MESSAGES} />
        </Box>
      ) : (
        <TextElement {...data} />
      );
    case 'image':
      return <img src={data.url} alt={data.alt_text} />;
    case ('action_button', 'link_button', 'email_button'):
      return <ButtonElement />;
    default:
      return null;
  }
}

function ChatBubble(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [anchor, setAnchor] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [rating, setRating] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const { setChatSidebarConfig } = useContext(SocketContext);

  const payload = props?.message?.payload ? JSON.parse(props.message.payload) : null;

  useEffect(() => {
    if (payload?.feedback?.type) {
      setRating(payload.feedback.type);
    }
  }, []);

  function handleOpenSourcesClick() {
    setChatSidebarConfig({
      sources: payload?.sources,
      thoughts: payload?.thought_process
    });
  }

  function handleCloseFeedbackWindow() {
    props.handleFeedbackSubmit({
      feedback: { type: rating, explanation: feedbackText },
      message_timestamp: props.message.timestamp
    });
    setAnchor(null);
    setFeedbackText('');
    enqueueSnackbar('Feedback saved!');
  }

  const enterSubmit = (e) => {
    if (e.charCode === 13 && feedbackText) {
      // e.preventDefault();
      handleCloseFeedbackWindow();
    }
  };

  const copyMessageContent = () => {
    setCopySuccess(true);
    const payloadToCopy = payload.message.elements
      .filter((element) => element.element === 'text')
      .map((element) => element.text);

    navigator.clipboard.writeText(payloadToCopy.join(', '));
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }} alignItems="flex-start" display="flex" flexWrap="wrap" key={props.message.index}>
      <Box className={classes.botMessage}>
        <Box className={classes.avatarHolder}>
          <Avatar
            src="/static/brand/responsum.png"
            imgProps={{ sx: { objectFit: 'contain', padding: '5px' } }}
            sx={{
              width: '33px',
              height: '33px',
              bgcolor: 'background.paper'
            }}
          />
        </Box>
        {props?.message?.payload ? (
          <>
            <Box className={classes.botMessageContent}>
              {payload?.message?.elements?.map((item) => (
                <Element data={item} />
              ))}
            </Box>
            <Box className={classes.thumbContainer}>
              <Typography className={classes.messageTimestamp} variant="caption">
                {props.message?.timestamp && formatDate(props.message?.timestamp)}
              </Typography>
              <Tooltip title="Thumbs Up">
                <IconButton
                  onClick={(e) => {
                    setAnchor(e.target);
                    setRating(POSITIVE_RATING);
                  }}
                >
                  {rating === POSITIVE_RATING ? (
                    <ThumbUp className={classes.thumbIcon} fontSize="small" />
                  ) : (
                    <ThumbUpAltOutlined className={classes.thumbIcon} fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Thumbs Down">
                <IconButton
                  onClick={(e) => {
                    setAnchor(e.target);
                    setRating(NEGATIVE_RATING);
                  }}
                >
                  {rating === NEGATIVE_RATING ? (
                    <ThumbDown fontSize="small" className={classes.thumbIcon} />
                  ) : (
                    <ThumbDownOutlined fontSize="small" className={classes.thumbIcon} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={copySuccess ? 'Message copied!' : 'Copy Message'}>
                <IconButton className={classes.sourceButton} onClick={copyMessageContent}>
                  <ContentCopy className={classes.thumbIcon} fontSize="small" />
                </IconButton>
              </Tooltip>
              {(Boolean(payload?.sources) || Boolean(payload?.thought_process)) && (
                <Tooltip title="Show Message Info">
                  <IconButton className={classes.sourceButton} onClick={() => handleOpenSourcesClick()}>
                    <InfoOutlined className={classes.thumbIcon} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </>
        ) : null}
        {payload.debug_info && <ReferenceList {...payload} />}
      </Box>
      <Dialog maxWidth="sm" fullWidth open={Boolean(anchor)} onClose={() => handleCloseFeedbackWindow()}>
        <DialogTitle>Provide additional feedback</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ mt: 1 }}
            onChange={(e) => setFeedbackText(e.target.value)}
            value={feedbackText}
            onKeyPress={(e) => enterSubmit(e)}
            multiline
            rows={6}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseFeedbackWindow()} size="small" variant="outlined" sx={{ mt: -3 }}>
            Submit Feedback
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default memo(
  ChatBubble,
  (prevProps, nextProps) => JSON.stringify(prevProps?.message) === JSON.stringify(nextProps?.message)
);
