import { io } from 'socket.io-client';
import { Auth } from 'aws-amplify';

const SOCKET_URL = process.env.REACT_APP_NOLA_BOT_URL;

let socket;

export const initiateSocket = async () => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  socket = io(SOCKET_URL, {
    path: '/ws/socket.io',
    auth: {
      token
    }
  });
  return socket;
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const connectSocket = async () => {
  if (socket) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    socket.auth.token = token;
    socket.connect();
  }
};

export const emitMessage = async (key, agentId, message, callback) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  socket.emit(
    key,
    {
      ...message,
      token,
      agent_id: agentId
    },
    (response) => {
      callback(response, message, key);
    }
  );
};

export const subscribeToEvent = (eventKey, callback) => {
  if (socket) {
    socket.on(eventKey, callback);
  }
};

export const removeSubscription = (eventKey, callback) => {
  if (socket) {
    socket.off(eventKey, callback);
  }
};
