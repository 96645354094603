import { alpha } from '@mui/material';

export default (theme) => ({
  resetedSignHolder: {
    position: 'relative',
    width: '100%'
  },
  resetSign: {
    color: theme.palette.grey[600],
    flex: 1,
    textAlign: 'center',
    padding: '15px 0',
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    marginTop: 30,
    marginBottom: 20
  },
  mainChatWrapper: {
    width: '100%',
    margin: 'auto',
    position: 'relative',
    height: '100vh',
    background: theme.palette.background.neutral
  },
  inputWrapper: {
    position: 'absolute',
    left: '50%',
    display: 'flex',
    width: '95%',
    maxWidth: '800px',
    transform: 'translateX(-50%)',
    bottom: 20,
    background: theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.dark,
    borderRadius: theme.shape.borderRadiusSm,
    boxShadow: `0 0px 50px 25px ${
      theme.palette.mode === 'light' ? theme.palette.background.dark : theme.palette.background.default
    }`,
    [theme.breakpoints.down('sm')]: {
      padding: 4
    }
  },
  chatHolder: {
    background: theme.palette.mode === 'light' ? theme.palette.background.dark : theme.palette.background.default,
    flex: 1,
    paddingLeft: 1,
    paddingRight: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  chatAlert: {
    position: 'absolute',
    zIndex: 1,
    mt: 5,
    width: 450,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  messageHolder: {
    '& > div:last-child': {
      marginBottom: 150
    },
    '& > div:first-child': {
      marginTop: 50
    }
  },
  botMessage: {
    padding: 15,
    width: '88%',
    maxWidth: '800px',
    position: 'relative',
    margin: 'auto',
    borderRadius: theme.shape.borderRadiusMd,
    boxShadow: `rgba(50, 50, 93, 0.10) 0px 2px 5px -1px, rgba(0, 0, 0, 0.15) 0px 1px 3px -1px`,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.default : '#212a35',
    color: theme.palette.mode === 'light' ? theme.palette.text.main : alpha(theme.palette.text.primary, 0.75),
    fontSize: '.95rem',
    lineHeight: '1.6rem',
    wordWrap: 'break-word',
    '& table': {
      borderSpacing: 0,
      borderRadius: 3,
      border: '.5px solid rgba(128, 128, 128, 0.4)',
      overflow: 'hidden',
      marginTop: 10
    },
    '& table th': {
      background: 'rgba(128, 128, 128, 0.1)',
      border: '.5px solid rgba(128, 128, 128, 0.4)',
      padding: 5
    },
    '& table td': {
      border: '.5px solid rgba(128, 128, 128, 0.4)',
      padding: 5
    },
    '& ul': {
      listStylePosition: 'inside',
      margin: '10px 0 10px 20px'
    },
    '& ol': {
      margin: '10px 0 10px 25px'
    },
    '& hr': {
      margin: '15px 0 15px 0',
      opacity: 0.25
    },
    '& ::marker': {
      color: alpha(theme.palette.text.primary, 0.5)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '.85rem',
      width: '95%'
    }
  },
  userMessage: {
    borderRadius: 12.5,
    borderTopRightRadius: 0,
    padding: '10px 15px',
    width: '88%',
    maxWidth: '800px',
    margin: 'auto',
    color: theme.palette.text.primary,
    fontSize: '.95rem',
    lineHeight: '1.6rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.85rem',
      width: '95%'
    }
  },
  messageTimestamp: {
    opacity: 0.7,
    marginRight: 'auto'
  },
  feedbackBox: {
    padding: 10,
    width: '400px',
    background: theme.palette.background.light
  },
  thumbIcon: {
    height: '16px',
    width: '16px',
    opacity: 0.7,
    pointerEvents: 'none'
  },
  thumbContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatarHolder: {
    position: 'absolute',
    top: 5,
    left: -45,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  chatbotLoader: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: '6px',
    width: 40
  },
  chatbotLoaderDot: {
    fill: theme.palette.text.secondary
  }
});
