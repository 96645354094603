import { useState, useContext } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  Card,
  useMediaQuery
} from '@mui/material';
/* import LaunchIcon from '@mui/icons-material/Launch'; */
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import { SocketContext } from '../../../contexts/SocketContext';
import { RIGHT_SIDEBAR_WIDTH } from '../../../constants';

function ChatRightSidebar() {
  const { setChatSidebarConfig, chatSidebarConfig } = useContext(SocketContext);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [debugFullscreen, setDebugFullscreen] = useState(false);

  function handleCloseSidebar() {
    setChatSidebarConfig(null);
  }

  return (
    <Drawer
      variant="persistent"
      open={Boolean(chatSidebarConfig)}
      anchor="right"
      hideBackdrop
      PaperProps={{
        sx: {
          height: '100vh'
        }
      }}
    >
      <Box
        sx={{
          maxWidth: isMobile ? '100%' : '400px',
          width: isMobile ? '100%' : RIGHT_SIDEBAR_WIDTH,
          p: 2
        }}
      >
        <IconButton sx={{ float: 'right', zIndex: 1 }} onClick={() => handleCloseSidebar()}>
          <CloseIcon />
        </IconButton>
        <List>
          {chatSidebarConfig?.sources && (
            <>
              <ListItem disableGutters>
                <Typography variant="h6">Sources</Typography>

                {/* <Chip size="small" sx={{ ml: 1.5, mr: 'auto' }} label={3} color="primary" /> */}
              </ListItem>
              <Card
                variant="outlined"
                sx={{
                  p: 1.5,
                  boxShadow: 'none'
                }}
              >
                <ReactMarkdown linkTarget="_blank">{chatSidebarConfig.sources}</ReactMarkdown>
              </Card>
              {/* 	
							{data.sources.map(listItem => (
								<ListItem key={listItem.fileName}>
									<ListItemText primary={listItem.fileName} />
									<Tooltip
										disableInteractive
										placement="bottom"
										title="Open document in new tab"
									>
										<IconButton color="primary">
											<LaunchIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								</ListItem>
							))} 
							*/}
            </>
          )}

          {chatSidebarConfig?.thoughts && (
            <>
              <ListItem disableGutters>
                <Typography variant="h6">Thoughts</Typography>
                {/* <Chip size="small" sx={{ ml: 1.5, mr: 'auto' }} label={3} color="primary" /> */}
              </ListItem>
              <Card
                variant="outlined"
                sx={{
                  p: 2,
                  bgcolor: 'background.default'
                }}
              >
                <ReactMarkdown linkTarget="_blank">{chatSidebarConfig?.thoughts}</ReactMarkdown>
              </Card>
              {/* 	
							{data.sources.map(listItem => (
								<ListItem key={listItem.fileName}>
									<ListItemText primary={listItem.fileName} />
									<Tooltip
										disableInteractive
										placement="bottom"
										title="Open document in new tab"
									>
										<IconButton color="primary">
											<LaunchIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								</ListItem>
							))} 
							*/}
            </>
          )}

          {chatSidebarConfig?.debug && (
            <>
              <ListItem disableGutters>
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Debug Log
                  <Tooltip title="Full Screen">
                    <IconButton
                      size="small"
                      color="primary"
                      sx={{
                        ml: 0.5
                      }}
                      onClick={() => setDebugFullscreen(true)}
                    >
                      <FullscreenIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                {/* <Chip size="small" sx={{ ml: 1.5, mr: 'auto' }} label={3} color="primary" /> */}
              </ListItem>
              {chatSidebarConfig?.debug.map((debugItem) => (
                <Box
                  key={debugItem.title}
                  sx={{
                    p: 1.5,
                    fontFamily: 'monospace',
                    fontSize: '13px',
                    bgcolor: 'background.neutral',
                    position: 'relative',
                    mt: 1
                  }}
                >
                  <Typography gutterBottom variant="subtitle1">
                    {debugItem.title}
                  </Typography>
                  {debugItem.content}
                </Box>
              ))}
              <Dialog maxWidth="lg" onClose={() => setDebugFullscreen(false)} open={debugFullscreen}>
                <IconButton onClick={() => setDebugFullscreen(false)} sx={{ position: 'absolute', right: 10, top: 1 }}>
                  <CloseIcon />
                </IconButton>
                <DialogContent>
                  {chatSidebarConfig?.debug.map((debugItem) => (
                    <Box sx={{ mt: 2 }} key={debugItem.title}>
                      <Typography gutterBottom variant="subtitle1">
                        {debugItem.title}
                      </Typography>
                      {debugItem.content}
                    </Box>
                  ))}
                </DialogContent>
              </Dialog>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
}

export default ChatRightSidebar;
