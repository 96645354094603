import { useState } from 'react';
import { Fade, Typography } from '@mui/material';
import useInterval from '../../hooks/useInterval';

function TextSlider({ textArray, interval }) {
  const [textIndex, setTextIndex] = useState([0, true]);
  const [intervalDelay, setIntervalDelay] = useState(interval);

  const switchTextIndex = () => {
    if (textIndex[0] + 1 < textArray.length) {
      setTextIndex([textIndex[0], false]);
      setTimeout(() => {
        setTextIndex([textIndex[0] + 1, true]);
      }, 1000);
    } else {
      setIntervalDelay(0);
    }
  };

  useInterval(switchTextIndex, intervalDelay);

  return (
    <Fade timeout={{ enter: 1000, exit: 1000 }} in={textIndex[1]}>
      <Typography variant="body2" sx={{ opacity: 0.8 }}>
        {textArray[textIndex[0]]}
      </Typography>
    </Fade>
  );
}

export default TextSlider;
