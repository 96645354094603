import { Auth } from 'aws-amplify';

export const loginUser = async (username, password) => {
  return /* await */ Auth.signIn(username, password);
};

export const registerUser = async (firstName, lastName, email, password, company) => {
  return /* await */ Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      given_name: firstName,
      family_name: lastName,
      'custom:company_name': company
    },
    autoSignIn: {
      enabled: true
    }
  });
};

export const confirmUser = async (username, code) => {
  return /* await */ Auth.confirmSignUp(username, code);
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};
