import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth, Hub } from 'aws-amplify';

// utils
/* import { isValidToken, setSession } from '../utils/jwt'; */
import { registerUser, confirmUser, signOut } from '../api/authentication';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isRegistered: false,
  user: null,
  userSettings: null,
  authMessage: null,
  errorMessage: null,
  forgotPasswordCode: false,
  resetAuthPassword: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user: user?.attributes,
      userSettings: user
    };
  },
  LOGIN: (state, action) => {
    return {
      ...state,
      isAuthenticated: true,
      user: action?.payload?.attributes,
      userSettings: action.payload
    };
  },
  LOGIN_ERROR: (state, action) => {
    const { err } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      authMessage: err.message
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isRegistered: true,
      user
    };
  },
  REGISTER_ERROR: (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
      errorMessage: action.payload
    };
  },
  CONFIRM_USER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isRegistered: true,
      user: user?.attributes,
      userSettings: user
    };
  },
  LOGIN_REGISTER_CONFIRMATION: (state) => {
    return {
      ...state,
      error: '',
      authenticated: false,
      isLoading: false,
      isRegistered: true
    };
  },
  FORGOT_PASSWORD: (state) => {
    return {
      ...state,
      forgotPasswordCode: true,
      authenticated: false,
      resetError: null
    };
  },
  FORGOT_PASSWORD_ERROR: (state, action) => {
    return {
      ...state,
      resetError: action.payload
    };
  },
  RESET_AUTH_PASSWORD: (state, action) => {
    return {
      ...state,
      resetAuthPassword: true,
      authenticated: false,
      resetError: null,
      unauthUser: action.payload
    };
  },
  CONFIRM_PASSWORD_ERROR: (state, action) => {
    return {
      ...state,
      confirmError: action.payload
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  confirm: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false
      });

      if (user?.attributes) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  async function login(username, password, loginWithUserPasswordAuthFlow = true) {
    if (loginWithUserPasswordAuthFlow) {
      Auth.configure({
        authenticationFlowType: 'USER_PASSWORD_AUTH'
      });
    } else {
      Auth.configure({
        authenticationFlowType: 'USER_SRP_AUTH'
      });
    }
    await Auth.signIn(username, password)
      .then((data) => {
        // inspect response 'data' and check whether
        // 2. New Password is required (change 'FORCE_CHANGE_PASSWORD'
        //    to 'CONFIRMED'), dispatch-> AUTH_NEW_PASSWORD_REQUIRED with payload
        // 3. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === 'NEW_PASSWORD_REQUIRED' || data.challengeName === 'FORCE_CHANGE_PASSWORD') {
          navigate('/auth/reset-password');
          dispatch({ type: 'RESET_AUTH_PASSWORD', payload: data });
        } else {
          // dispatch AUTH_USER
          Auth.currentAuthenticatedUser({ bypassCache: true }).then((currentAuthUser) => {
            dispatch({
              type: 'LOGIN',
              payload: currentAuthUser
            });
          });
        }
        return data;
      })
      .catch((err) => {
        console.log('Error sign in', err);
        if (err.code === 'UserNotConfirmedException') {
          navigate('/auth/register');

          Auth.resendSignUp(username)
            .then(() => {
              dispatch({ type: 'LOGIN_REGISTER_CONFIRMATION' });
            })
            .catch(() => {
              console.log('auth error');
              /* dispatch(authError(error)); */
            });
        } else if (err.code === 'PasswordResetRequiredException') {
          navigate('/auth/reset-password');
        } else if (err.code === 'UserNotFoundException') {
          err.message = 'User does not exist.';
        } else {
          err.message = 'Incorrect username and/or password';
        }

        dispatch({
          type: 'LOGIN_ERROR',
          payload: {
            err
          }
        });
        return null;
      });
  }

  function setNewPassword(newPassword, cognitoUser) {
    listenForReset();

    // completeNewPassword (cognito)
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then((data) => {
        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch -> AUTH_MFA
        // 2. otherwise, authenticate user, dispatch -> AUTH_USER

        dispatch({ type: 'LOGIN', payload: data });
        initialize();
        navigate('/');
      })
      .catch((err) => {
        console.error('actions.setNewPassword():Auth.completeNewPassword() err:', err);
      });
  }

  const register = async (firstName, lastName, email, password, company) => {
    listenForRegistration();
    try {
      const user = await registerUser(firstName, lastName, email, password, company);
      dispatch({
        type: 'REGISTER',
        payload: {
          user: user.user
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const confirm = async (code) => {
    try {
      await confirmUser(state.user.username, code);
      login();
    } catch (error) {
      console.log(error);
    }
  };

  const listenForRegistration = () => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;

      if (event === 'autoSignIn') {
        const user = payload.data;
        dispatch({
          type: 'CONFIRM_USER',
          payload: {
            user
          }
        });
      }

      if (event === 'signUp_failure') {
        dispatch({
          type: 'REGISTER_ERROR',
          payload: payload.data?.message
        });
      }
    });
  };

  const listenForReset = () => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'forgotPassword_failure') {
        dispatch({
          type: 'FORGOT_PASSWORD_ERROR',
          payload: payload.data.message
        });
      }
      if (event === 'completeNewPassword_failure') {
        dispatch({
          type: 'CONFIRM_PASSWORD_ERROR',
          payload: payload.data.message
        });
      }
    });
  };

  const logout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = (username) => {
    listenForReset();

    return Auth.forgotPassword(username)
      .then(() => {
        dispatch({ type: 'FORGOT_PASSWORD' });
      })
      .catch((err) => {
        console.error('actions.forgotPassword():Auth.forgotPassword() err:', err);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        confirm,
        logout,
        register,
        resetPassword,
        setNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
