import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Box, List, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import NavSearch from './NavSearch';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.disabled,
  flexGrow: 0,
  justifyContent: 'center',
  paddingLeft: 38,
  paddingRight: 38,
  transition: '150ms',
  '&:before': {
    bottom: 0,
    right: 0,
    width: '100%',
    height: 2,
    content: "''",
    opacity: 0,
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    transition: '150ms'
  },
  '&:hover:before': {
    opacity: 1
  },
  '&:hover': {
    backgroundColor: 'inherit',
    color: theme.palette.common.black
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, isShow }) {
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
    cursor: 'default',
    '&:hover:before': {
      opacity: 0
    }
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          disableRipple
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        </ListItemStyle>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      disableRipple
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
        {navConfig.items.map((item) => (
          <NavItem key={item.title} item={item} isShow={isShow} />
        ))}
      </List>
    </Box>
  );
}
