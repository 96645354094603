import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { enqueueSnackbar } from 'notistack';

// material
import { Link, Stack, Alert, TextField, IconButton, InputAdornment, Divider, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Microsoft } from '@mui/icons-material';

// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, authMessage } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [isLogging, setIsLogging] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });
  const queryString = window.location.search;
  const showEmailLogin = new URLSearchParams(queryString).get('email');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSSOClick = async (provider) => {
    try {
      setIsLogging(true);
      await Auth.federatedSignIn({ provider });
    } catch (error) {
      setIsLogging(false);
      enqueueSnackbar('Error signing in', { variant: 'error' });
      console.log('error', error);
    }
  };
  console.log(isLogging);
  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Welcome to Frontier Prospecting
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {showEmailLogin ? 'Enter your details below.' : 'Sign in by pressing button below'}
          </Typography>
        </Box>
      </Stack>
      <FormikProvider value={formik}>
        {showEmailLogin ? (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
              {authMessage && <Alert severity="error">{authMessage}</Alert>}
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                InputLabelProps={{ shrink: true }}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                InputLabelProps={{ shrink: true }}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
                  Forgot password?
                </Link>
              </Stack>{' '}
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Sign In
              </LoadingButton>
              <Divider>or</Divider>
              <LoadingButton
                loading={isLogging}
                startIcon={<Microsoft />}
                size="large"
                variant="outlined"
                onClick={() => handleSSOClick('MicrosoftEntra')}
              >
                Sign In with Microsoft
              </LoadingButton>
            </Stack>
          </Form>
        ) : (
          <LoadingButton
            loading={isLogging}
            size="large"
            variant="contained"
            onClick={() => handleSSOClick('MicrosoftEntra')}
          >
            Sign In
          </LoadingButton>
        )}
      </FormikProvider>
    </>
  );
}
