// hooks
import { MAvatar } from '../../components/@material-extend';
import createAvatar from '../../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  return (
    <MAvatar sx={{ p: 0.4 }} alt={other.username} color={createAvatar(other.username).color} {...other}>
      {createAvatar(other.username).name}
    </MAvatar>
  );
}
