import { Box } from '@mui/material';
import Chat from './Chat';
/* import Sidebar from './Sidebar'; */
import RightSidebar from './RightSidebar';

function ChatContainer() {
  return (
    <Box display="flex">
      {/* <Sidebar /> */}
      <Chat />
      <RightSidebar />
    </Box>
  );
}

export default ChatContainer;
