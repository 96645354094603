import { API } from 'aws-amplify';
import { subDays, formatISO } from 'date-fns';
import { CONFIGURATION } from '../app_configuration';

const API_PREFIX = '/prospects';
const GEN_API_PREFIX = '/generate';

export const getProspectsCount = () => {
  return API.get(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/count`);
};

export const getProspectsDetails = (id) => {
  return API.get(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/company/${id}`);
};

export const getLocationDetails = (id) => {
  return API.get(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/location/${id}`);
};

export const addNote = (pid, cid, note) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/add-note`, {
    response: true,
    body: { prospect_id: pid, contact_id: cid, note }
  });
};

export const generateContent = (contentType, contact) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${GEN_API_PREFIX}/generate-content`, {
    response: true,
    body: { content_type: contentType, contact }
  });
};

export const searchActiveProspects = (
  term,
  from,
  size,
  showContacted = true,
  showUnassigned = true,
  orderBy,
  order
) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/search-active`, {
    response: true,
    body: {
      search_term: term,
      pagination_from: from,
      pagination_size: size,
      show_contacted: showContacted,
      show_unassigned: showUnassigned,
      sort_by: orderBy,
      sort_ascending: order === 'asc'
    }
  });
};

export const searchNetNewLeads = (term, from, size, showContacted = true, showUnassigned = true, orderBy, order) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/search-net-new-leads`, {
    response: true,
    body: {
      search_term: term,
      pagination_from: from,
      pagination_size: size,
      show_contacted: showContacted,
      show_unassigned: showUnassigned,
      sort_by: orderBy,
      sort_ascending: order === 'asc'
    }
  });
};

export const searchContractRenewals = (
  term,
  from,
  size,
  showContacted = true,
  showUnassigned = true,
  orderBy,
  order
) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/search-contract-renewals`, {
    response: true,
    body: {
      search_term: term,
      pagination_from: from,
      pagination_size: size,
      show_contacted: showContacted,
      show_unassigned: showUnassigned,
      sort_by: orderBy,
      sort_ascending: order === 'asc'
    }
  });
};

export const searchAccountExpansions = (
  term,
  from,
  size,
  showContacted = true,
  showUnassigned = true,
  orderBy,
  order
) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/search-account-expansions`, {
    response: true,
    body: {
      search_term: term,
      pagination_from: from,
      pagination_size: size,
      show_contacted: showContacted,
      show_unassigned: showUnassigned,
      sort_by: orderBy,
      sort_ascending: order === 'asc'
    }
  });
};

export const searchAccount = (term) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/search-accounts-global`, {
    response: true,
    body: {
      search_term: term
    }
  });
};

export const generateActivityReport = (days = 10) => {
  return API.post(CONFIGURATION.AWS_API_NAME, `${API_PREFIX}/generate-logged-events-report`, {
    body: {
      date_from: formatISO(subDays(new Date(), days), { representation: 'date' }),
      date_to: formatISO(new Date(), { representation: 'date' })
    }
  });
};
