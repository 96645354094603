import { useState, useEffect, createContext, useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';
import {
  initiateSocket,
  subscribeToEvent,
  removeSubscription,
  disconnectSocket,
  connectSocket,
  emitMessage
} from '../socketio.service';

const initialState = {
  messages: []
};

const AGENT_ID = process.env.REACT_APP_NOLA_AGENT_ID;

export const SocketContext = createContext(initialState);

function SocketProvider({ children }) {
  const isConnectedRef = useRef(false);
  const messagesRef = useRef(false);

  const { enqueueSnackbar } = useSnackbar();

  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [botMessageLoading, setBotMessageLoading] = useState(false);
  const [chatSidebarConfig, setChatSidebarConfig] = useState(null);

  useEffect(() => {
    isConnectedRef.current = isConnected;
  }, [isConnected]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    initSocket();

    return () => {
      detachListeners();
    };
  }, []);

  const filterAndSortMessages = (upcomingMessages, messageHistory) => {
    const currentMessages = messageHistory ?? [];
    return [...upcomingMessages, ...currentMessages]
      .sort((a, b) => a.index - b.index)
      .filter((v, i, x) => x.findIndex((v2) => v2.index === v.index) === i)
      .filter((message) => message.index || message.index === 0);
  };

  const updateMessageWithToken = (event, isFinal) => {
    if (!isFinal && !isStreaming) {
      setIsStreaming(true);
    }
    if (messagesRef.current?.length) {
      const targetId = isFinal ? event.updated_item?.timestamp : event.message_id;
      const messageToUpdateIndex = messagesRef?.current?.findIndex((message) => message.timestamp === targetId);
      const updatedMessage = {
        ...messagesRef?.current?.[messageToUpdateIndex],
        payload: isFinal ? event.updated_item?.payload : JSON.stringify(event.message)
      };
      const updatedMessages = [...messagesRef.current];
      updatedMessages.splice(messageToUpdateIndex, 1, updatedMessage);
      setMessages(updatedMessages);

      if (isFinal) {
        setIsStreaming(false);
        setBotMessageLoading(false);
      }
    }
  };

  const saveChatHistory = (history) => {
    setMessages(history);
  };

  const updateHistory = (snapshot) => {
    let newList = snapshot.messages ?? snapshot.history_snapshot.messages;
    if (messages?.length) {
      newList = filterAndSortMessages(newList, messages);
    }
    setHistoryLoading(false);
    setBotMessageLoading(false);
    setMessages(newList);
  };

  function socketStatusCallback(response) {
    if (!response.success) {
      setIsStreaming(false);
      enqueueSnackbar(
        'There was an error while performing an action, please try again or reload the page if the issue persist.',
        {
          variant: 'error'
        }
      );
    }
  }

  function emitSocketMessage(key, payload) {
    emitMessage(key, AGENT_ID, payload, socketStatusCallback);
  }

  function onConnect() {
    setIsConnected(true);
  }

  function resetSession() {
    emitSocketMessage('reset_agent_session');
  }

  function resetChatHistory() {
    emitSocketMessage('clear_agent_history');
  }

  function getHistorySnapshot(lastMessageTs, snapshotSize) {
    emitSocketMessage('request_agent_history_snapshot', {
      last_message_ts: lastMessageTs,
      snapshot_size: snapshotSize
    });
  }

  function onDisconnect(reason, details) {
    console.log('reason', reason, details);
    setIsConnected(false);
  }

  function getWelcomeMessage() {
    emitSocketMessage('request_agent_welcome_message');
  }

  function handleHistoryLoad(snapshot) {
    updateHistory(snapshot);
  }

  function handleIncomingTokenStream(event) {
    updateMessageWithToken(event);
  }

  function handleFinalMessageUpdate(event) {
    updateMessageWithToken(event, true);
  }

  function handleFeedbackSubmit(feedbackData) {
    emitSocketMessage('post_agent_message_feedback', feedbackData);
  }

  function reconnectSocket(e) {
    if (!e.target.hidden && !isConnectedRef.current) {
      connectSocket();
    }
  }

  const initSocket = useCallback(async () => {
    await initiateSocket();
    subscribeToEvent('connect', onConnect);
    subscribeToEvent('disconnect', onDisconnect);
    subscribeToEvent('agent_sends_history_snapshot', handleHistoryLoad);
    subscribeToEvent('agent_streams_message', handleIncomingTokenStream);
    subscribeToEvent('agent_updates_history_item', handleFinalMessageUpdate);
    document.addEventListener('visibilitychange', reconnectSocket);
  }, []);

  const detachListeners = () => {
    removeSubscription('disconnect', onDisconnect);
    removeSubscription('agent_sends_history_snapshot', handleHistoryLoad);
    removeSubscription('agent_streams_message', handleIncomingTokenStream);
    removeSubscription('agent_updates_history_item', handleFinalMessageUpdate);
    document.removeEventListener('visibilitychange', reconnectSocket);
    disconnectSocket();
  };

  return (
    <SocketContext.Provider
      value={{
        handleFeedbackSubmit,
        getWelcomeMessage,
        getHistorySnapshot,
        setHistoryLoading,
        setBotMessageLoading,
        setChatSidebarConfig,
        emitMessage: emitSocketMessage,
        saveChatHistory,
        resetSession,
        resetChatHistory,
        isConnected,
        isStreaming,
        chatSidebarConfig,
        isLoading: botMessageLoading,
        isLoadingHistory: historyLoading,
        messageHistory: messages
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export default SocketProvider;
