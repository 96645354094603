import { useState, forwardRef } from 'react';
import { Dialog, Fab, Slide, Tooltip, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import ExpandCircleUpOutlinedIcon from '@iconify/icons-material-symbols/expand-circle-up-outline';
import Chat from './index';
import SocketProvider from '../../contexts/SocketContext';
// eslint-disable-next-line prefer-arrow-callback
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function ChatWrapper() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  return (
    <SocketProvider>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <Chat />
        <Fab
          size={isMobile ? 'medium' : 'large'}
          onClick={() => setOpen(false)}
          sx={{ position: 'fixed', right: isMobile ? 20 : 25, bottom: isMobile ? 90 : 20 }}
          color="action"
        >
          <Icon fontSize={25} icon={ExpandCircleUpOutlinedIcon} color="#fff" />
        </Fab>
      </Dialog>
      <Tooltip open title="Chat with GigaAgent" placement="top-start" arrow>
        <Fab
          size={isMobile ? 'medium' : 'large'}
          onClick={() => setOpen(true)}
          sx={{ position: 'fixed', right: 25, bottom: 20 }}
          color="action"
        >
          <img style={{ height: isMobile ? 28 : 35 }} alt="responsum logo" src="/static/brand/logo-single.svg" />
        </Fab>
      </Tooltip>
    </SocketProvider>
  );
}

export default ChatWrapper;
