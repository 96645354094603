import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { InputAdornment, TextField, Button, useMediaQuery } from '@mui/material';

function ChatInput({ onSubmit, onMessageChange, message, inputAdornment }) {
  const [inputFocused, setInputFocused] = useState(false);

  const onFocus = () => setInputFocused(true);
  const onBlur = () => setInputFocused(false);

  const enterSubmit = (e) => {
    if (e.charCode === 13) {
      onSubmit(e);
      e.preventDefault();
      return false;
    }
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <TextField
      aria-label="Type your message here..."
      placeholder="Type your message here..."
      value={message}
      focused={inputFocused}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={(e) => enterSubmit(e)}
      onChange={onMessageChange}
      fullWidth
      autoFocus
      multiline
      maxRows={6}
      minRows={1}
      color="action"
      InputProps={{
        sx: { borderRadius: 1.5, p: 1, mr: isMobile ? 10 : 0 },
        startAdornment: <InputAdornment position="start">{inputAdornment}</InputAdornment>,
        endAdornment: (
          <Button
            onClick={onSubmit}
            sx={{
              minWidth: 50,
              minHeight: 42,
              ml: 1
            }}
            variant="contained"
            color="action"
            disabled={!message.length}
          >
            <SendIcon fontSize="small" />
          </Button>
        )
      }}
    />
  );
}

export default ChatInput;
