import { createContext, useState, useRef } from 'react';
import { API } from 'aws-amplify';
import {
  getProspectsCount,
  searchActiveProspects,
  getProspectsDetails,
  searchNetNewLeads,
  searchContractRenewals,
  searchAccountExpansions
} from '../api/prospects';

const ProspectsContext = createContext();

const ERR_MESSAGE = 'Failed to fetch resources, please reload the page. If the issue persists please contact support.';
const ABORTED_MESSAGE = 'Request aborted';

function ProspectsContextProvider({ children }) {
  const previousFunctionRef = useRef(null);

  const [count, setCount] = useState();
  const [list, setList] = useState();
  const [netNewLeadsList, setNetNewLeadsList] = useState();
  const [contractRenewalsList, setContractRenewalsList] = useState();
  const [accountExpansionsList, setAccountExpansionsList] = useState();
  const [prospectDetails, setProspectDetails] = useState({});
  const [errors, setErrors] = useState([]);

  const searchList = async (term, from, size, contracted, unassigned, orderBy, order, callback) => {
    const instance = searchActiveProspects(term, from, size, contracted, unassigned, orderBy, order);
    if (previousFunctionRef.current) {
      API.cancel(previousFunctionRef.current, ABORTED_MESSAGE);
    }
    previousFunctionRef.current = instance;
    try {
      const res = await instance;
      setList(res.data);
    } catch (err) {
      if (err.message !== ABORTED_MESSAGE) {
        setErrors([...errors, { message: ERR_MESSAGE }]);
      }
    }
    callback();
  };

  const searchNetNewLeadsList = async (term, from, size, contracted, unassigned, orderBy, order, callback) => {
    const instance = searchNetNewLeads(term, from, size, contracted, unassigned, orderBy, order);
    if (previousFunctionRef.current) {
      API.cancel(previousFunctionRef.current, ABORTED_MESSAGE);
    }
    previousFunctionRef.current = instance;

    try {
      const res = await instance;
      setNetNewLeadsList(res.data);
    } catch (err) {
      console.log(err, err.response);
      if (err.message !== ABORTED_MESSAGE) {
        setErrors([...errors, { message: ERR_MESSAGE }]);
      }
    }
    callback();
  };

  const searchContractRenewalsList = async (term, from, size, contracted, unassigned, orderBy, order, callback) => {
    const instance = searchContractRenewals(term, from, size, contracted, unassigned, orderBy, order);
    if (previousFunctionRef.current) {
      API.cancel(previousFunctionRef.current, ABORTED_MESSAGE);
    }
    previousFunctionRef.current = instance;
    try {
      const res = await instance;
      setContractRenewalsList(res.data);
    } catch (err) {
      if (err.message !== ABORTED_MESSAGE) {
        setErrors([...errors, { message: ERR_MESSAGE }]);
      }
    }
    callback();
  };

  const searchAccountExpansionsList = async (term, from, size, contracted, unassigned, orderBy, order, callback) => {
    const instance = searchAccountExpansions(term, from, size, contracted, unassigned, orderBy, order);
    if (previousFunctionRef.current) {
      API.cancel(previousFunctionRef.current, ABORTED_MESSAGE);
    }
    previousFunctionRef.current = instance;
    try {
      const res = await instance;
      setAccountExpansionsList(res.data);
    } catch (err) {
      if (err.message !== ABORTED_MESSAGE) {
        setErrors([...errors, { message: ERR_MESSAGE }]);
      }
    }
    callback();
  };

  const fetchProspectsResources = async () => {
    try {
      const response = await getProspectsCount();
      setCount(response);
    } catch (error) {
      setErrors([...errors, { message: ERR_MESSAGE }]);
    }
  };

  const fetchProspectDetails = async (id, callback) => {
    try {
      const res = await getProspectsDetails(id);
      setProspectDetails({ ...prospectDetails, [id]: res });
    } catch (error) {
      setErrors([...errors, { message: ERR_MESSAGE }]);
    }
    if (callback instanceof Function) {
      callback();
    }
  };

  const removeError = (index) => {
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  return (
    <ProspectsContext.Provider
      value={{
        prospectCount: count,
        prospectList: list,
        prospectErrors: errors,
        prospectDetails,
        netNewLeadsList,
        contractRenewalsList,
        accountExpansionsList,
        removeError,
        searchList,
        searchNetNewLeadsList,
        searchContractRenewalsList,
        fetchProspectDetails,
        fetchProspectsResources,
        searchAccountExpansionsList
      }}
    >
      {children}
    </ProspectsContext.Provider>
  );
}

export { ProspectsContext, ProspectsContextProvider };
