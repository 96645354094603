export const CONFIGURATION = {
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  AWS_COGNITO_DOMAIN: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
  AWS_API_NAME: process.env.REACT_APP_AWS_API_NAME,
  AWS_API_URL: process.env.REACT_APP_AWS_API_URL,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
};
