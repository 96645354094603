import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from '../../../hooks/useAuth';
import MyAvatar from '../../../layouts/dashboard/MyAvatar';
import styles from '../styles';

function UserChatBubble(props) {
  const useStyles = makeStyles(styles);
  const { user } = useAuth();
  const classes = useStyles();
  const messageText =
    typeof props.message?.message === 'string'
      ? props.message?.message
      : JSON.parse(props.message.payload).message.elements[0].text;
  return props.message.payload_type === 'session_reset' ? (
    <Box
      key={props.message.index}
      alignItems="center"
      justifyContent="center"
      display="flex"
      className={classes.resetedSignHolder}
    >
      <Typography className={classes.resetSign} variant="caption">
        {messageText}
      </Typography>
    </Box>
  ) : (
    <Box
      key={props.message.index}
      alignItems="center"
      justifyContent="flex-end"
      display="flex"
      sx={{ position: 'relative', width: '100%', mt: 3, mb: 3 }}
    >
      <Box className={classes.userMessage}>
        <Box className={classes.avatarHolder}>
          <MyAvatar sx={{ width: '32px', height: '32px' }} username={user?.email} />
        </Box>
        {messageText}
      </Box>
    </Box>
  );
}

export default memo(
  UserChatBubble,
  (prevProps, nextProps) =>
    prevProps.message === nextProps.message ||
    (prevProps.message.payload && prevProps.message.payload === nextProps.message.payload)
);
