import React, { useEffect, useState, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Alert, LinearProgress, Skeleton, CircularProgress, useMediaQuery } from '@mui/material';
import RSC from 'react-scrollbars-custom';
import UserChatBubble from '../UserChatBubble';
import BotChatBubble from '../BotChatBubble';
import ChatInput from '../ChatInput';
import ChatToolbar from '../ChatToolbar';
import styles from '../styles';
import './styles.css';
import { RIGHT_SIDEBAR_WIDTH } from '../../../constants';
import { SocketContext } from '../../../contexts/SocketContext';

const useStyles = makeStyles(styles);

function Chat() {
  const classes = useStyles();

  const {
    getHistorySnapshot,
    getWelcomeMessage,
    resetSession,
    resetChatHistory,
    emitMessage,
    handleFeedbackSubmit,
    saveChatHistory,
    setBotMessageLoading,
    setHistoryLoading,
    messageHistory,
    isStreaming,
    isConnected,
    isLoading,
    isLoadingHistory,
    chatSidebarConfig
  } = useContext(SocketContext);

  const isOverlay = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [message, setMessage] = useState('');
  const [disableInput /* , setDisableInput */] = useState(false);
  const [lastScrollHeight, setLastScrollHeight] = useState(null);
  const [streamError, setStreamError] = useState(null);

  const scrollRef = useRef(null);
  const messageHistoryRef = useRef(messageHistory);
  const timeoutRef = useRef(null);
  const AGENT_ID = process.env.REACT_APP_NOLA_AGENT_ID;

  useEffect(() => {
    getHistorySnapshot();
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (!messageHistory) return;
    const scroller = scrollRef.current;

    if (!isStreaming && !messageHistoryRef.current?.[AGENT_ID]) {
      scrollToBottom();
    }
    if (isStreaming && scroller.scrollTop + 150 > scroller.scrollHeight - scroller.clientHeight) {
      scrollToBottom();
    }
    if (lastScrollHeight && scrollRef?.current?.scrollHeight !== lastScrollHeight) {
      scroller.scrollTop = scroller.scrollHeight - lastScrollHeight;
      setLastScrollHeight(null);
    }
    messageHistoryRef.current = messageHistory;
  }, [messageHistory]);

  useEffect(() => {
    if (messageHistory?.[AGENT_ID] && !messageHistory?.[AGENT_ID]?.messages?.length) {
      getWelcomeMessage();
    }
  }, [messageHistory]);

  useEffect(() => {
    if (isStreaming) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setStreamError(true);
      }, 240000);
    }
  }, [messageHistory]);

  useEffect(() => {
    if (timeoutRef.current && !isStreaming) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [isStreaming]);

  const loadHistory = () => {
    setHistoryLoading(true);
    getHistorySnapshot(messageHistoryRef.current?.[AGENT_ID].messages[0].timestamp, 15);
  };

  const scrollCallback = (position) => {
    if (position.scrollTop < 10 && messageHistory?.[AGENT_ID]?.history_snapshot?.messages_before && !isLoadingHistory) {
      setLastScrollHeight(position.scrollHeight);
      loadHistory();
    }
  };

  const handleResetSessionClick = () => {
    resetSession();
  };

  const handleResetAllClick = () => {
    saveChatHistory([]);
    resetChatHistory();
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current?.scrollToBottom();
    }
  };

  const onSubmitMessage = () => {
    if (isLoading || isStreaming || !message) return;
    setBotMessageLoading(true);
    setTimeout(() => {
      scrollToBottom();
    }, 500);

    emitMessage('send_message_to_agent', {
      message
    });

    saveChatHistory([
      ...messageHistory,
      {
        origin: 'user',
        message
      },
      {
        origin: 'bot',
        payload: JSON.stringify({
          message: {
            elements: [{ element: 'text', text: '', html_to_markdown: false }],
            surface: 'chat_message'
          },
          sources: null,
          debug_info: null
        })
      }
    ]);
    setMessage('');
  };

  console.log(messageHistory);

  return (
    <Box
      sx={{
        height: `100vh`,
        mr: Boolean(chatSidebarConfig) && !isOverlay ? RIGHT_SIDEBAR_WIDTH : 0
      }}
      data-state={isStreaming || isLoading ? 'disabled' : 'enabled'}
      className={classes.chatHolder}
    >
      {isConnected ? (
        <Box position="relative" sx={{ width: '100%' }}>
          {streamError && (
            <Alert onClose={() => setStreamError(null)} className={classes.chatAlert} severity="error">
              Seems like there was an error while generating response. Please refresh the page if the issue persists.
            </Alert>
          )}
          {isLoadingHistory && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }} />}
          <Box className={classes.mainChatWrapper}>
            <RSC
              ref={(scrollBarRef) => (scrollRef.current = scrollBarRef)}
              style={{ height: '100%' }}
              onScroll={(scrollData) => scrollCallback(scrollData)}
            >
              <Box className={classes.messageHolder}>
                {messageHistory ? (
                  messageHistory?.map((message) =>
                    message?.origin === 'user' ? (
                      <UserChatBubble key={message.index} message={message} />
                    ) : (
                      <BotChatBubble
                        key={message.index}
                        message={message}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                      />
                    )
                  )
                ) : (
                  <Box sx={{ width: '95%', maxWidth: '950px', margin: 'auto' }} display="flex" flexDirection="column">
                    {[...new Array(11)].map((idx) => (
                      <Box display="flex" key={idx}>
                        <Skeleton
                          sx={{
                            mr: 3,
                            bgcolor: 'background.default'
                          }}
                          variant="circular"
                          width={35}
                          height={35}
                        />
                        <Skeleton
                          variant="rectangular"
                          height={100}
                          width="90%"
                          sx={{
                            borderRadius: 1.5,
                            mb: 8,
                            bgcolor: 'background.default'
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </RSC>
            {!disableInput && (
              <Box className={classes.inputWrapper}>
                <ChatInput
                  message={message}
                  onMessageChange={onMessageChange}
                  onSubmit={onSubmitMessage}
                  inputAdornment={
                    <ChatToolbar
                      resetSession={handleResetSessionClick}
                      resetAll={handleResetAllClick}
                      isMobile={isMobile}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Alert
          color="action"
          sx={{ mt: 5, bgColor: 'background.neutral' }}
          icon={<CircularProgress color="action" size={24} />}
        >
          Waiting for the server...
        </Alert>
      )}
    </Box>
  );
}

export default Chat;
