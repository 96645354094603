import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Chip, IconButton, Button } from '@mui/material';
import { Settings, ArrowOutward } from '@mui/icons-material';
// components
import AccountPopover from './AccountPopover';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import NavSearch from '../../components/NavSearch';
import sidebarConfig from './SidebarConfig';
import { ADMIN_ROLE } from '../../constants';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  flexWrap: 'wrap',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    paddingTop: 13
  }
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const { user } = useAuth();

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ mr: 3 }} component={RouterLink} to="/">
          <Logo />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <NavSearch />
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {user['custom:role'] === ADMIN_ROLE && (
            <IconButton to="/settings" LinkComponent={RouterLink}>
              <Settings fontSize="small" />
            </IconButton>
          )}
          <AccountPopover />
          <Button
            variant="outlined"
            color="action"
            endIcon={<ArrowOutward />}
            href="mailto:support@iolap.com?subject=GigaPro Support Request"
          >
            Support
          </Button>
        </Stack>
      </ToolbarStyle>
      <Box sx={{ width: '100%' }}>
        <NavSection navConfig={sidebarConfig} />
      </Box>
    </RootStyle>
  );
}
