// ----------------------------------------------------------------------

export default function TableRow(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: theme.palette.grey[100]
            }
          }
        },
        hover: {
          backgroundColor: theme.palette.grey[100]
        }
      }
    }
  };
}
