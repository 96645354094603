import { Auth } from 'aws-amplify';

const API_VERSION = '/api/v1';

const config = require('./app_configuration').CONFIGURATION;

export const amplifyConfig = {
  Auth: {
    identityPoolId: config.AWS_COGNITO_IDENTITY_POOL_ID,
    region: config.AWS_REGION,
    userPoolId: config.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.AWS_COGNITO_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.AWS_API_NAME,
        endpoint: `${config.AWS_API_URL}${API_VERSION}`,
        region: config.AWS_REGION,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        })
      }
    ]
  },
  oauth: {
    domain: config.AWS_COGNITO_DOMAIN,
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code'
  }
};
