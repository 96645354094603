import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, symbolOnly = false }) {
  return (
    <Box sx={{ width: 145, position: 'relative', height: 35, ...sx }}>
      {symbolOnly ? (
        <img alt="logo" src="/static/brand/logo-single.svg" />
      ) : (
        <>
          <img alt="logo" src="/static/brand/logo_full.png" />
          <Typography
            variant="subtitle2"
            color="primary"
            sx={{
              position: 'absolute',
              bottom: -7,
              left: 0,
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '.8rem'
            }}
          >
            GigaPro
          </Typography>
        </>
      )}
    </Box>
  );
}
