import { SnackbarProvider } from 'notistack';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import useAuth from './hooks/useAuth';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  return (
    <ThemeConfig>
      <SnackbarProvider>
        <ThemePrimaryColor>
          <GlobalStyles />
          <ProgressBarStyle />
          <ScrollToTop />
          {isInitialized ? <Router /> : <LoadingScreen />}
        </ThemePrimaryColor>
      </SnackbarProvider>
    </ThemeConfig>
  );
}
